import React, { useEffect, useContext, useRef, useState }  from 'react'

// MUI
import Box from '@mui/material/Box'
import styled from '@mui/material/styles/styled'

// GS
import { gsap } from 'gsap'

import { MouseContext } from '/src/context/mouseContext'

import ClickToView from '/static/icons/icon-clicktoview.svg'
import ClickToDrag from '/static/icons/icon-clicktodrag.svg'
import CopiedOk from '/static/icons/icon-ok.svg'

const BoxCursor = styled( Box )(({ theme, lang }) => ({
    zIndex: theme.zIndex.cursor,
    position: 'fixed',
    top: '0',
    left: '0',
    opacity: '0',
    pointerEvents: 'none',
    borderRadius: '50%',
    display: 'inline-block',
    fontSize: '0',
    lineHeight: '0',
    fontFamily: 'PPNeueMachinaRegular',
    '&.hover':{
        fontSize: '3.556px',
        lineHeight: '4px',
        '& .inner':{
            cursor: 'pointer',
            transform: 'scale(1)',
            backgroundColor: theme.palette.u92.hotpink,
            textAlign: 'center',
            '& .inner-text': {
                transform: 'scale(1)',
                opacity: 1
            }
        },
        '& svg':{
            visibility: 'visible'
        }
    },
    '& svg':{
        width: '100%',
        height: '100%',
        visibility: 'hidden'
    },
    '&.hover-nofill-notext':{
        '& .inner': {
            backgroundColor: 'transparent !important',
            borderWidth: '3px !important',
            '& .innerText': {
                opacity: '0 !important',
            }
        }
    },
    '&.hover-no-text': {
        '& .inner-text': {
            opacity: '0 !important'
        }
    },
    '& .inner':{
        width: '117px',
        height: '117px',
        borderRadius: '50%',
        border: '10px solid '+theme.palette.u92.hotpink,
        opacity: '0.9',
        transition: 'transform 350ms ease',
        position: 'relative',
        transform: 'scale(0.25)',
        display: 'flex',
        justifyContent: 'center',
    },
    // '& .inner-text':{
    //     opacity: 0,
    //     fontSize: '0.9375rem',
    //     lineHeight: '0.9375rem',
    //     transform: 'scale(0.1) translate3D(-50%, -50%, 0)',
    //     display: 'flex',
    //     alignSelf: 'center',
    //     '&:after':{
    //         content: (lang==='en') ? '"Click to view"' : '"Cliquez pour voir!"'
    //     }
    // },
    '&.click-to-copy': {
        '& .inner-text': {
            opacity: 1,
            fontSize: '0.9375rem',
            lineHeight: '0.9375rem',
            transform: 'scale(0.1) translate3D(-50%, -50%, 0)',
            display: 'flex',
            alignSelf: 'center',
            '&:after': {
                content: (lang==='en') ? '"Click to copy!"' : '"Cliquez pour copier!"'
            },
        },
        '&.copied': {
            '& .inner-text': {
                '&:after': {
                    content: (lang==='en') ? '"Copied!"' : '"Copié!"'
                }
            }
        }
    },
    // '&.click-and-drag': {
    //     '& .inner-text': {
    //         opacity: 1,
    //         '&:after': {
    //             content: (lang==='en') ? '"Click and drag"' : '"Cliquez et glissez"'
    //         },
    //     }
    // }
}));

const Cursor = ( props ) => {

    const lang = props.lang;
    const ref = useRef(null);
    const [classes, setClasses] = useState('');

    const context = useContext( MouseContext );
    const cursorType = context.cursorType;

    // init
    useEffect(() => {

        const isBrowser = typeof window !== "undefined";
        const cursor = ref.current;
        
        let initCursor = false;
        gsap.set(cursor, {xPercent: -50, yPercent: -50, opacity: 0});
        cursor.classList.remove("hover");

        let pos = 0;
        if (isBrowser) {
            pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
            // pos = { x: 0, y: 0 };
        }
        const mouse = { x: pos.x, y: pos.y };
        const speed = 0.15;

        const xSet = gsap.quickSetter(cursor, "x", "px");
        const ySet = gsap.quickSetter(cursor, "y", "px");

        if (isBrowser) {
            
            document.body.addEventListener("mouseenter", e => {    
                if (!initCursor) {
                    gsap.to(cursor, {
                        duration: 0.3,
                        opacity: 0.9
                    });
                    initCursor = true;
                }
            });

            document.body.addEventListener("mousemove", e => {    
                mouse.x = e.x;
                mouse.y = e.y;
            });

            document.body.addEventListener("mouseleave", e => {
                gsap.to(cursor, {
                    duration: 0.3,
                    opacity: 0
                });
                initCursor = false;
            });
        }

        gsap.ticker.add(() => {
            // adjust speed for higher refresh monitors
            const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
            pos.x += (mouse.x - pos.x) * dt;
            pos.y += (mouse.y - pos.y) * dt;
            xSet(pos.x);
            ySet(pos.y);
        });
        
    },[]);

    // when cursorType changes
    useEffect(() => {

        setClasses(cursorType);
        // console.info('cursorType: '+cursorType);

    }, [cursorType]);

    return (
        <BoxCursor ref={ref} lang={lang} className={ classes } id="custom-cursor" component="span">
            <span className='inner'>
                
                <span className="inner-text" />

                { ( classes && classes.includes('click-to-view') ) &&
                    <ClickToView className="icon-clicktoview" />
                }

                { ( classes && classes.includes('click-to-drag') ) &&
                    <ClickToDrag className="icon-clicktodrag" />
                }

                { ( classes && classes.includes('copiedok') ) &&
                    <CopiedOk className="icon-ok" />
                }

            </span>
        </BoxCursor>
    )
}

export default Cursor