const palette = {

    theme: {
        color1: '#30A7E2',
        color2: '#7E52FF',
        color3: '#E9554D',
        color4: '#82FF48',
        color5: '#E8FB52',
        color6: '#F547F9'
    },
    
    u92: {
        bluefluo: '#31FFEE',
        green: '#82FF48',
        hotpink: '#F547F9',
        lightblue: '#5BD6E6',
        midgrey: '#C4C4C4',
        richblack: '#1F1E1C',
        softwhite: '#F2F3F5',
        pinknike: '#DA487D',
        purple: '#7E52FF',
        warmwhite: '#FFFCEF',
        white: '#FFFFFF',
        black: '#000000',
        ocre: '#F2C972',
        peagreen: "#B5D946",
        fuschia: '#DA487D'
    }
 
};

export default palette;