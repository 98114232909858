import React, { useEffect } from 'react'

import { useCookies } from 'react-cookie'

// MUI
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/system/useTheme'

import Cursor from '/src/components/lib/cursor'
import { getUrl } from '/src/utils/function'

export default function WrapLayout( props ) {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const isBrowser = typeof window !== "undefined";
    const cookieName = 'browser-lang-redirect';
    const [cookies, setCookie] = useCookies([cookieName]);

    const appHeight = () => {
        document.querySelector(':root').style.setProperty('--vh', window.innerHeight/100 + 'px');
    }

    useEffect(() => {

        if( isBrowser ){

            window.addEventListener('resize', () => { 
                appHeight();
            });

            appHeight();

        }

    },[])

    useEffect(() => {

        if( !cookies[cookieName] ){

            if(  !navigator.language.toLowerCase().includes( props.pageContext.lang )  ){

                // redirect en EN
                if( navigator.language.toLowerCase().includes( 'en' ) ){
                    window.location = getUrl( 'en', (props.pageContext.slug ? props.pageContext?.slug['en'] : props.uri) );
                }

                // redirect to FR
                if( navigator.language.toLowerCase().includes( 'fr' ) ){
                    window.location = getUrl( 'fr', (props.pageContext.slug ? props.pageContext?.slug['fr'] : props.uri) );
                }

            }

            setCookie(cookieName, true, { path: '/' });
        }

    })
    
    return (
        <>
            { matches &&
                <Cursor />
            }
            { props.children }
        </>
    );
}