import React, { createContext, useMemo, useState } from 'react'

const MouseContext = createContext({
    cursorType: '',
    setCursorType: () => {},
});

const MouseContextProvider = (props) => {

    const [cursorType, setCursorType] = useState('');

    const value = useMemo(
        () => ({ cursorType, setCursorType }), 
        [cursorType]
    );

    return (
        <MouseContext.Provider value={ value }>
            { props.children }
        </MouseContext.Provider>
    );
};

export { MouseContextProvider, MouseContext };