import { getClamp } from '/src/utils/function'

// 100: Thin, Hairline, Ultra-light, Extra-light
// 200: Light
// 300: Book
// 400: Regular, Normal, Plain, Roman, Standard
// 500: Medium
// 600: Semi-bold, Demi-bold
// 700: Bold
// 800: Heavy, Black, Extra-bold
// 900: Ultra-black, Extra-black, Ultra-bold, Heavy-black, Fat, Poster

export function setTypography( theme ){

    theme.typography.h1 = {
        fontWeight: 700,
        [theme.breakpoints.up('xl')]: {
            // fontSize: 80,
            lineHeight: (96/80)
        },
        [theme.breakpoints.down('xl')]: {
            // fontSize: '5.556vw',
            lineHeight: (6.667/5.556).toFixed(3)
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 48,
            lineHeight: (58/48)
        },
        fontSize: getClamp(48, 80)
    };

    theme.typography.h2bold = {
        fontFamily: '"Barlow", sans-serif;',
        fontWeight: 700,
        [theme.breakpoints.up('xl')]: {
            // fontSize: 60,
            lineHeight: (72/60)
        },
        [theme.breakpoints.down('xl')]: {
            // fontSize: '5.556vw',
            lineHeight: (6.667/5.556)
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 32,
            lineHeight: (40/32)
        },
        fontSize: getClamp(32, 60)
    };

    theme.typography.h2 = {
        fontFamily: '"Barlow", sans-serif;',
        fontWeight: 400,
        [theme.breakpoints.up('xl')]: {
            // fontSize: 60,
            lineHeight: (72/60)
        },
        [theme.breakpoints.down('xl')]: {
            // fontSize: '5.556vw',
            lineHeight: (6.667/5.556).toFixed(3),
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 32,
            lineHeight: (40/32)
        },
        fontSize: getClamp(32, 60)
    };
    
    theme.typography.h2p = {
        fontFamily: '"Barlow", sans-serif;',
        fontWeight: 700,
        [theme.breakpoints.up('xl')]: {
            fontSize: 60,
            lineHeight: (72/60)
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: 46,
            lineHeight: (56/46)
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 32,
            lineHeight: (40/32)
        }
    };

    theme.typography.h2plus = {
        fontFamily: '"Barlow", sans-serif;',
        fontWeight: 700,
        [theme.breakpoints.up('xl')]: {
            fontSize: 60,
            lineHeight: (92/60)
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '3.125vw',
            lineHeight: (4.791/3.125)
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 48,
            lineHeight: (57.6/48)
        }
    };
    
    theme.typography.h3 = {
        fontFamily: 'PPNeueMachinaExtraBold',
        fontWeight: 800,
        [theme.breakpoints.up('xl')]: {
            // fontSize: 28,
            lineHeight: (40/28)
        },
        [theme.breakpoints.down('xl')]: {
            // fontSize: '1.944vw',
            lineHeight: (2.778/1.944)
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 20,
            lineHeight: (28/20)
        },
        fontSize: getClamp(20, 28)
    };
    theme.typography.h3barlow = {
        fontFamily: '"Barlow", sans-serif;',
        fontWeight: 600,
        fontSize: getClamp(20, 28),
        lineHeight: getClamp(30, 40)
    };

    theme.typography.h3barlowlineheight = {
        fontFamily: '"Barlow", sans-serif;',
        fontWeight: 600,
        fontSize: getClamp(20, 28),
        lineHeight: getClamp(20, 30)
    };
    
    theme.typography.h4 = {
        fontFamily: 'PPNeueMachinaExtraBold',
        fontWeight: 800,
        [theme.breakpoints.up('xl')]: {
            fontSize: 24,
            lineHeight: (32/24)
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '1.667vw',
            lineHeight: (2.222/1.667)
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: (24/16)
        }
    };

    theme.typography.h5 = {
        fontFamily: 'PPNeueMachinaRegular',
        fontWeight: 400,
        fontSize: 18,
        lineHeight: (24/18)
    };
    
    theme.typography.listitem = {
        fontFamily: 'PPNeueMachinaRegular',
        fontWeight: 400,
        [theme.breakpoints.up('xl')]: {
            // fontSize: 24,
            lineHeight: (60/24)
        },
        [theme.breakpoints.down('xl')]: {
            // fontSize: '1.667vw',
            lineHeight: (4.167/1.667)
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 18,
            lineHeight: (32/18)
        },
        fontSize: getClamp(18, 24)
    };
    
    theme.typography.cta = {
        fontFamily: 'PPNeueMachinaExtraBold',
        fontWeight: 800,
        [theme.breakpoints.up('xl')]: {
            // fontSize: 16,
            lineHeight: (24/16)
        },
        [theme.breakpoints.down('xl')]: {
            // fontSize: '1.667vw',
            lineHeight: (1.667/1.111)
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 12,
            lineHeight: (18/12)
        },
        fontSize: getClamp(12, 16)
    };

    theme.typography.stats = {
        fontFamily: 'Barlow',
        fontWeight: 800,
        [theme.breakpoints.up('xl')]: {
            fontSize: 160,
            lineHeight: (162/160)
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '8.333vw',
            lineHeight: (8.4375/8.333)
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 48,
            lineHeight: (57.6/48)
        }
    };
    
    theme.typography.pextralarge = {
        fontFamily: '"Barlow", sans-serif;',
        fontWeight: 700,
        [theme.breakpoints.up('xl')]: {
            // fontSize: 130,
            lineHeight: (162/130).toFixed(3)
        },
        [theme.breakpoints.down('xl')]: {
            // fontSize: '9.028vw',
            lineHeight: (11.250/9.028).toFixed(3)
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 48,
            lineHeight: (58/48).toFixed(3)
        },
        fontSize: getClamp(48, 130),
    };

    theme.typography.h3extralarge = {
        fontFamily: '"Barlow", sans-serif;',
        fontWeight: 700,
        // [theme.breakpoints.up('xl')]: {
        //     // fontSize: 130,
        //     lineHeight: (162/130)
        // },
        // [theme.breakpoints.down('xl')]: {
        //     // fontSize: '9.028vw',
        //     // lineHeight: (3.90625/6.771)
        //     lineHeight: (8.4375/6.771)
        // },
        // [theme.breakpoints.down('sm')]: {
        //     // fontSize: 36,
        //     lineHeight: (40/36)
        // },
        fontSize: getClamp(36, 130),
        lineHeight: getClamp(40, 120)
    };
    
    theme.typography.plarge = {
        fontFamily: 'PPNeueMachinaRegular',
        fontWeight: 400,
        [theme.breakpoints.up('xl')]: {
            // fontSize: 24,
            lineHeight: (40/24).toFixed(3)
        },
        [theme.breakpoints.down('xl')]: {
            // fontSize: '1.667vw',
            lineHeight: (2.778/1.667).toFixed(3)
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 20,
            lineHeight: (32/20).toFixed(3)
        },
        fontSize: getClamp(20, 24)
    };

    theme.typography.psmall = {
        fontFamily: 'PPNeueMachinaRegular',
        fontWeight: 400,
        [theme.breakpoints.up('xl')]: {
            fontSize: 18,
            lineHeight: (40/18)
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '1.250vw',
            lineHeight: (2.778/1.250)
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            lineHeight: (40/18)
        }
    };

    theme.typography.psmalllineheight = {
        fontFamily: 'PPNeueMachinaRegular',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '30px'
    };

    theme.typography.pmediumsmall = {
        fontFamily: 'PPNeueMachinaRegular',
        fontWeight: 400,
        [theme.breakpoints.up('xl')]: {
            // fontSize: 14,
            lineHeight: (24/14)
        },
        [theme.breakpoints.down('xl')]: {
            // fontSize: '0.972vw',
            lineHeight: (1.667/0.972)
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 14,
            lineHeight: (24/14)
        },
        fontSize: getClamp(12, 16)
    };
    
    theme.typography.pextrasmall = {
        fontFamily: 'PPNeueMachinaRegular',
        fontWeight: 400,
        [theme.breakpoints.up('xl')]: {
            // fontSize: 14,
            lineHeight: (24/14)
        },
        [theme.breakpoints.down('xl')]: {
            // fontSize: '0.972vw',
            lineHeight: (1.667/0.972)
        },
        [theme.breakpoints.down('sm')]: {
            // fontSize: 14,
            lineHeight: (24/14)
        },
        fontSize: getClamp(14, 14)
    };
    theme.typography.pextrasmallbold = {
        fontFamily: 'PPNeueMachinaBold',
        fontWeight: 400,
        fontSize: getClamp(12, 14),
        lineHeight: getClamp(20, 24)
    };
    
    theme.typography.notfound = {
        fontFamily: 'PPNeueMachinaRegular',
        fontWeight: 400,
        [theme.breakpoints.up('xl')]: {
            fontSize: 80,
            lineHeight: (98/80)
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '5.556vw',
            lineHeight: (6.806/5.556)
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 26.6666666667,
            lineHeight: 32.6666666667/26.6666666667
        }
    };
    
    theme.typography.menu = {
        fontFamily: 'PPNeueMachinaRegular',
        fontWeight: 400,
        // [theme.breakpoints.up('xl')]: {
        //     // fontSize: 60,
        //     lineHeight: 112/60
        // },
        // [theme.breakpoints.down('xl')]: {
        //     // fontSize: '4.167vw',
        //     lineHeight: (7.778/4.167)
        // },
        // [theme.breakpoints.down('sm')]: {
        //     // fontSize: 30,
        //     lineHeight: 52/30
        // },
        fontSize: getClamp(20, 45),
        lineHeight: getClamp(40, 90)
    };
    
    theme.typography.quote = {
        fontFamily: '"Barlow", sans-serif;',
        fontWeight: 700,
        [theme.breakpoints.up('xl')]: {
            fontSize: 80,
            lineHeight: 98/80
        },
        [theme.breakpoints.down('xl')]: {
            fontSize: '4.166vw',
            lineHeight: (5.104/4.166)
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 32,
            lineHeight: 40/32
        }
    };

}