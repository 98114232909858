import React, { useEffect, useContext, useState } from 'react'

// MUI
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import styled from '@mui/material/styles/styled'
import useTheme from '@mui/system/useTheme'

import U92LogoComponent from '/static/images/u92-logo.svg'
import theme from '/src/theme/theme'
import { Context } from '/src/components/loadingContext'

const U92Logo = styled(U92LogoComponent)({
    animation: 'rotation 4s infinite linear',
    height: '87px',
    width: '87px',
    fill: theme.palette.theme.color5,
    '@keyframes rotation': {
        '0%': {
            transform: 'rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(359deg)'
        }
    }
});

const Loading = () => {

    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const { setLoading } = useContext(Context);

    useEffect(() => {

        setTimeout(function(){
            setOpen(false)
            setLoading(true);
        }, 1500)
 
    }, []);

    return (
        <Dialog
            fullScreen
            open={open}
            sx={{
                backgroundColor: "u92.richblack",
                zIndex: theme.zIndex.loading
            }} >

            <Container 
                maxWidth="100%"
                sx={{ 
                    backgroundColor: "u92.richblack",
                    // height: '100vh',
                    height: 'calc(100 * var(--vh))',
                    width: '100vw'
                }}>
                
                <Box 
                    sx={{ 
                        display: 'flex',
                        alignItem: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        alignItems: 'center'
                    }} >
                    
                    <U92Logo />
            
                </Box>

            </Container>

        </Dialog>
    )
}

export default Loading