import PPNeueMachinaUltrabold from '/static/fonts/PPNeueMachina/PPNeueMachina-Ultrabold.woff2'
import PPNeueMachinaExtrabold from '/static/fonts/PPNeueMachina/PPNeueMachina-Black.woff2'
import PPNeueMachinaLight from '/static/fonts/PPNeueMachina/PPNeueMachina-Light.woff2'
import PPNeueMachinaRegular from '/static/fonts/PPNeueMachina/PPNeueMachina-Regular.woff2'
import PPNeueMachinaBold from '/static/fonts/PPNeueMachina/PPNeueMachina-Bold.woff2'

import { createTheme } from '@mui/material/styles'

import palette from '/src/theme/palette'
import { setTypography } from '/src/theme/typography'

const theme = createTheme({
    typography: {
        fontFamily: [
            '"Barlow", sans-serif;',
            'PPNeueMachinaLight',
            'PPNeueMachinaRegular',
            'PPNeueMachinaBold',
            'PPNeueMachinaUltraBold',
            'PPNeueMachinaExtrabold'
        ].join(',')
    },
    palette,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1440,
            xxl: 1920
        }
    },
    zIndex: {
        loading: 1600,
        transition: 1599,
        cursor: 1700
    },
    components: {
        MuiModal: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    height: 'calc(100 * var(--vh))'
                }
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    height: '100%'
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'PPNeueMachinaLight';
                    font-style: 'normal';
                    font-display: 'swap',
                    font-weight: 300;
                    src: url('${PPNeueMachinaLight}') format('woff2');
                    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
                }
                @font-face {
                    font-family: 'PPNeueMachinaRegular';
                    font-style: 'normal';
                    font-display: 'swap',
                    font-weight: 400;
                    src: url('${PPNeueMachinaRegular}') format('woff2');
                    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
                }
                @font-face {
                    font-family: 'PPNeueMachinaBold';
                    font-style: 'normal';
                    font-display: 'swap',
                    font-weight: 400;
                    src: url('${PPNeueMachinaBold}') format('woff2');
                    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
                }
                @font-face {
                    font-family: 'PPNeueMachinaExtraBold';
                    font-style: 'normal';
                    font-display: 'swap',
                    font-weight: 800;
                    src: url('${PPNeueMachinaExtrabold}') format('woff2');
                    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
                }
                @font-face {
                    font-family: 'PPNeueMachinaUltraBold';
                    font-style: 'normal';
                    font-display: 'swap',
                    font-weight: 900;
                    src: url('${PPNeueMachinaUltrabold}') format('woff2');
                    unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
                }
            `,
        }
    }
});

setTypography(theme);

// mobile stepper: 1000
// speed dial: 1050
// app bar: 1100
// drawer: 1200
// modal: 1300
// snackbar: 1400
// tooltip: 1500

export default theme;