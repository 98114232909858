import React from 'react'

import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { AnimatePresence } from 'framer-motion'

// MUI
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
import ThemeProvider from '@mui/material/styles/ThemeProvider'

import theme from '/src/theme/theme'
import { setCustomStyles } from '/src/theme/customStyles'
import Loading from '/src/components/loading'
import { LoadingContextProvider } from '/src/components/loadingContext'
import { MouseContextProvider } from '/src/context/mouseContext'

export default function TopLayout(props) {
    
    const customStyles = setCustomStyles( theme );

    return (
        <>
            <Helmet>
                {/* <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" /> */}
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
                <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@400;600;700&display=swap" rel="stylesheet" />
                <link rel="icon" href="/favicon/android-chrome-192x192.png" sizes="192x192" />
                <link rel="icon" href="/favicon/android-chrome-512x512.png" sizes="512x512" />
                <link rel="apple-touch-icon" href="/favicon/apple-touch-icon.png" />
                <link rel="icon" href="/favicon/favicon-32x32.png" sizes="32x32" />
                <link rel="icon" href="/favicon/favicon-16x16.png" sizes="16x16" />
                { process.env.GATSBY_ENV !== 'production' &&
                    <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=meivxnwdvge3bucwvcf1iq" async="true"></script>
                }
            </Helmet>

            <LoadingContextProvider>
                <MouseContextProvider>
                    <ThemeProvider theme={theme}>
                        
                        <CssBaseline />
                        <GlobalStyles styles={ customStyles } />

                        <Loading />

                        <AnimatePresence exitBeforeEnter>
                            {props.children}
                        </AnimatePresence>

                    </ThemeProvider>
                </MouseContextProvider>
            </LoadingContextProvider>
        </>
    );
}

TopLayout.propTypes = {
    children: PropTypes.node,
};