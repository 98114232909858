// normalize CSS across browsers
// import "./src/css/normalize.css"

import React from 'react';
import TopLayout from '/src/components/topLayout';
import WrapLayout from '/src/components/wrapLayout';

export const wrapRootElement = ({ element }) => {
    return <TopLayout>{element}</TopLayout>;
};

export const wrapPageElement = ({ element, props }) => {
    return <WrapLayout {...props}>{element}</WrapLayout>
};

export const onInitialClientRender = () => {
    // console.log("ReactDOM.render has executed");
    window.scrollTo(0, 0);
}

export const onRouteUpdate = () => {
    // console.log("onRouteUpdate");
    window.scrollTo(0, 0);
}

export const shouldUpdateScroll = () => {
    // console.log("shouldUpdateScroll");
    window.scrollTo([0, 0])
    window.scrollTo(0, 0)
    return false
}