function findByKey(obj, keyToFind){
    
    var result = '';
    var found = false;

    function eachRecursive( obj, keyToFind ){

        for (var k in obj){

            if( k === keyToFind ){

                result = obj[k];
                found = true;
                break;

            }else{

                if (typeof obj[k] == "object" && obj[k] !== null && !found ){
                    eachRecursive( obj[k], keyToFind );
                }

            }
        }

    }

    eachRecursive( obj, keyToFind )

    return result;
}

const getClamp = ( mindim, maxdim, minvw = 360, maxvw = 1920 ) => {

    const slope = (maxdim - mindim) / (maxvw - minvw)                   
    const yAxisIntersection = -minvw * slope + mindim                   
    const preferredValue = yAxisIntersection.toFixed(3)+'px + ' + (slope * 100).toFixed(3)+'vw'
    
    return 'clamp( '+mindim+'px, '+preferredValue+', '+maxdim+'px  )';
}

const getUrl = (lang, slug) => {

    let url = '';

    if( slug === '/' ){
        url = '/'+lang;
    }

    if( slug === '/en' ){
        url =  '/';
    }

    if( lang === 'fr' ){
        url =  '/';
    }

    if( url === '' ){
        url = '/'+lang+'/'+slug;
    }

    return url.replace('//', '/');
}

const stripHTML = (input) => {
    return input.replace(/(<([^>]+)>)/gi, "");
}

export { findByKey, getClamp, getUrl, stripHTML };