import { getClamp } from '/src/utils/function'

const setCustomStyles = ( theme ) => {

    return {

        'html, *': {
            cursor: 'none'
        },

        a: { 
            cursor: 'none'
        },
        
        'a:hover': { 
            cursor: 'none'
        },

        'svg:hover': { 
            cursor: 'none'
        },

        'svg > *': { 
            cursor: 'none'
        },

        'button': { 
            cursor: 'none'
        },

        '.MuiButtonBase-root.MuiButton-root:hover': {
            backgroundColor: 'transparent',
            cursor: 'none'
        },

        '.MuiBackdrop-root': {
            background: 'transparent',
            backdropFilter: 'blur(20px)'
        },

        '.MuiModal-root.MuiDialog-root': {
            cursor: 'none'
        },
        
        '.MuiContainer-root.MuiContainer-maxWidthXxl': {
            paddingRight: getClamp(20, 120),
            paddingLeft: getClamp(20, 120)
        },

        '.MuiModal-root': {
            cursor: 'none',
            '& .modal-close-icon': {
                position: 'absolute',
                top: 50,
                right: 50,
                '& svg path': { 
                    stroke: theme.palette.u92.softwhite
                },
                '&:hover path': { 
                    stroke: theme.palette.u92.hotpink
                },
                [theme.breakpoints.down("sm")]: {
                    top: 10,
                    right: 0,
                }
            },
            '& .modal-slide': {
                backgroundColor: 'rgba(31, 30, 28, 0.8)',
                height: '100%'
            },
            '& .modal-slide.modal-light': {
                backgroundColor: 'rgba(242, 243, 245, 0.95)',
            }
        }
    
    }

}

export { setCustomStyles };