import React, { createContext, useMemo, useState } from 'react'

const Context = createContext({
    loading: false,
    setLoading: () => {},
})

const LoadingContextProvider = props => {

    const [loading, setLoading] = useState(false);

    const value = useMemo(
        () => ({ loading, setLoading }), 
        [loading]
    );

    return (
        <Context.Provider value={ value }>
            {props.children}
        </Context.Provider>
    )
}

export { LoadingContextProvider, Context }